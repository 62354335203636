<template>
  <div v-bind:class="[{ selected: selected }]">
    <div
      class="btn-payment"
      v-bind:class="{ select: selected, 'has-error': hasError }"
      @click="selected ? false : $emit('click')"
    >
      <img
        width="40px"
        height="40px"
        class="w-full fit-contain"
        :src="image"
      />

      <div>
        {{ name }}
      </div>

      <div v-if="label">
        {{ label }}
      </div>
    </div>
  </div>
</template>

<script>
import colorBnn from '@/config/color'

export default {
  name: 'ButtonPayment',
  props: {
    name: String,
    label: String,
    image: String,
    selected: Boolean,
    hasError: Boolean,
    items: {
      type: Array,
      default: () => null,
    },
  },
  data() {
    return {
      colorBnn,
      installmentPlan: this.items ? this.items[0] : null,
    }
  },
  methods: {
    handleChange() {
      this.$emit('updateInstallmentPlan', this.installmentPlan)
    },
  },
  watch: {
    selected(newVal) {
      if (newVal && this.items?.length > 0) {
        this.installmentPlan = this.items[0] ?? null
        this.handleChange()
      }
    },
  },
}
</script>

<style lang="stylus" scoped>
.btn-payment
    width 100%
    background white
    cursor pointer
    box-shadow: 0 0 0 1px $color-bnn-gray-light;
    border-radius: 4px;
    height: auto;
    transition: 0.2s ease-in-out;
    text-align: center;
    padding: 12px
    height: 100%
    @media (hover: hover) and (pointer: fine)
        &:hover
          border-color: transparent;
          box-shadow: 0 0 0 1px $color-bnn-border;
          transition: 0.2s ease-in-out;
          // border-color: $color-dark-gray;
    .text-btn-payment
        font-size 1rem
    @media medium
        .text-btn-payment
            font-size 1.2rem
    @media small
        .text-btn-payment
            font-size 0.8rem
.btn-payment.select
    box-shadow: 0 0 0 1px $color-bnn;
    border-color: transparent;
.btn-payment.has-error
     border-color: red;
</style>
